














































































































import { InvoiceModel, InvMatchMode } from 'truemarket-modules/src/models/api/invoices'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { DateModel } from 'truemarket-modules/src/models/api/common'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import { GetApiAccessTokenModel } from 'truemarket-modules/src/models/api/api_access_tokens'

@Component
export default class InvoicesIndex extends Vue {
  public loading = false;
  public totalRecords = 0;
  public invoices: InvoiceModel[] = [];
  public filters: { [key: string]: any } | null = null;
  public statuses = ['Paid', 'Authorised', 'Draft', 'Voided', 'None'];
  public lazyParams: any = {};

  public apiAccessTokens: GetApiAccessTokenModel[] = [];
  public selectedClientName: any = null;
  public clientNames: any[] = [];
  public selectedKeyName: any = null;
  public keyNames: any[] = [];

  created () {
    this.initFilters()
  }

  mounted () {
    const dt = this.$refs.dt as any

    this.loading = true
    this.lazyParams = {
      first: 0,
      rows: dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters
    }

    this.loadLazyData()
    this.loadLookups()
  }

  loadLazyData () {
    this.loading = true

    Services.API.Payment.PostInvoicesFilter(this.lazyParams).then(data => {
      this.invoices = data.Invoices
      this.totalRecords = data.TotalCount
      this.loading = false
    })
  }

  loadLookups () {
    Services.API.APIAccessTokens.GetApiAccessTokens().then((apiAccessTokens) => {
      this.apiAccessTokens = apiAccessTokens
    }).catch(() => {
      this.apiAccessTokens = []
    })
  }

  @Watch('selectedClientName')
  updateKeyNames () {
    const newKeyNames = [...new Set(this.apiAccessTokens.filter(token => token.ClientName === this.selectedClientName?.name)
      .map(token => token.KeyName))]
      .map(key => ({ name: key }))
    newKeyNames.unshift({ name: 'All' })
    this.keyNames = newKeyNames
  }

  @Watch('apiAccessTokens')
  updateClientNames () {
    const newClientNames = [...new Set(this.apiAccessTokens.map(token => token.ClientName))]
      .map(client => ({ name: client }))
    newClientNames.unshift({ name: 'All' }, { name: 'TrueMarket Website' }, { name: 'All API' })
    this.clientNames = newClientNames
  }

  onPage (event: any) {
    this.lazyParams = event
    this.loadLazyData()
  }

  onFilter (_event: any) {
    this.lazyParams.filters = this.filters
    this.loadLazyData()
  }

  onApplyClientKeyFilter () {
    this.resolveClientKeyFilter()
    this.lazyParams.filters = this.filters
    this.loadLazyData()
  }

  onClearFilters () {
    this.selectedClientName = null
    this.selectedKeyName = null
    this.initFilters()
    this.lazyParams.filters = this.filters
    this.loadLazyData()
  }

  resolveClientKeyFilter () {
    if (!this.filters) return

    const clientName = this.selectedClientName?.name
    const keyName = this.selectedKeyName?.name
    const globalOptions = ['All', 'TrueMarket Website', 'All API']

    if (clientName === 'All') {
      this.filters.ClientName = { value: null, matchMode: InvMatchMode.ALL }
      this.filters.KeyName = { value: null, matchMode: InvMatchMode.ALL }
    }

    if (clientName === 'TrueMarket Website') {
      this.filters.ClientName = { value: null, matchMode: InvMatchMode.NULL }
      this.filters.KeyName = { value: null, matchMode: InvMatchMode.NULL }
    }

    if (clientName === 'All API') {
      this.filters.ClientName = { value: null, matchMode: InvMatchMode.NOT_NULL }
      this.filters.KeyName = { value: null, matchMode: InvMatchMode.NOT_NULL }
    }

    if (clientName !== null && !globalOptions.includes(clientName)) {
      this.filters.ClientName = { value: clientName, matchMode: InvMatchMode.EQUAL }
      if (keyName === 'All') this.filters.KeyName = { value: null, matchMode: InvMatchMode.NOT_NULL }
      if (keyName !== null && keyName !== 'All') this.filters.KeyName = { value: keyName, matchMode: InvMatchMode.EQUAL }
    }
  }

  initFilters () {
    this.filters = {
      InvoiceNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'CreateDate.Value': {
        operator: FilterOperator.AND,
        constraints: [
          { value: null, matchMode: FilterMatchMode.DATE_AFTER },
          { value: null, matchMode: FilterMatchMode.DATE_BEFORE }
        ]
      },
      FormattedStatus: { value: null, matchMode: FilterMatchMode.IN },
      'PaidDate.Value': {
        operator: FilterOperator.AND,
        constraints: [
          { value: null, matchMode: FilterMatchMode.DATE_AFTER },
          { value: null, matchMode: FilterMatchMode.DATE_BEFORE }
        ]
      },
      Subtotal: {
        operator: FilterOperator.AND,
        constraints: [
          { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO }
        ]
      },
      Total: {
        operator: FilterOperator.AND,
        constraints: [
          { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO }
        ]
      },
      ClientName: { value: null, matchMode: InvMatchMode.ALL },
      KeyName: { value: null, matchMode: InvMatchMode.ALL }
    }
  }

  exportFunction (data: any) {
    if (data.field === 'InvoiceId') {
      const inv = this.invoices.filter(inv => inv.InvoiceId === data.data)[0]
      return this.getAddress(inv)
    }

    return data.data
  }

  exportCSV (_event: any) {
    const dt = this.$refs.dt as any
    dt.exportCSV()
  }

  editInvoice (row: any) {
    this.$router.push(`/dashboard/admin/invoices/${row.InvoiceId}`)
  }

  formatDate (date: DateModel) {
    return date ? date.FormattedDate : ''
  }

  formatCurrency (value: number | null) {
    return value
      ? value.toLocaleString('en-US', { style: 'currency', currency: 'AUD' })
      : ''
  }

  getAddress (data: InvoiceModel) {
    return data.InvoiceItems[0]?.Description ?? ''
  }
}
